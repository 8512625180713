@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap);
@font-face {
  font-family: 'Capisce-Display';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Capisce-Display.38841e8e.otf) format('opentype');
}

@font-face {
  font-family: 'Capisce-Italic';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Capisce-Italic.1d085d05.otf) format('opentype');
}

@font-face {
  font-family: 'Capisce-Text';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Capisce-Text.8c0677d0.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format('woff2'),
    url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff)
      format('woff');
}

